import {
  borderedInputWhite,
  buttonWhiteSmall,
  label,
} from "../../../../shared/styles";
import globalState from "../../../../store";
import { useAtom } from "jotai";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../../../../App";
import {
  FormattedStripePlansByTier,
  ICoupon,
  IProphoneContact,
  IStripePlan,
  StripePlansResponse,
} from "./types";
import {
  filterCoupons,
  getAllStripeCoupons,
  getProphoneContacts,
  transformStripePlans,
} from "./utils";
import {
  FREE_TRIAL_PLANS,
  NO_COUPON_SELECTED,
  NO_PLAN_SELECTED,
} from "./constants";

const CheckoutLink: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [checkoutLink, setCheckoutLink] = useState("");

  const [proPhoneContacts, setProPhoneContacts] = useState<IProphoneContact[]>(
    []
  );
  const [formattedStripePlans, setFormattedStripePlans] = useState<
    FormattedStripePlansByTier[]
  >([]);
  const [stripeCoupons, setStripeCoupons] = useState<ICoupon[]>([]);
  const [selectedProphoneContact, setSelectedProphoneContact] = useState(
    globalStateAtom?.prophone_contact_id || ""
  );
  const [showCheckoutLink, setShowCheckoutLink] = useState(false);
  const { saveClickedCount } = useContext(AppContext);

  useEffect(() => {
    getPlansFromStripe();
    getCouponsFromStripe();
  }, []);

  useEffect(() => {
    async function getLandingPage() {
      const slug = globalStateAtom?.slug;
      const fetchedDataResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/getLandingPage/${slug}`
      );

      let checkoutLink = `app.toplinepro.com/checkout?id=${globalStateAtom.sitePk}`;
      
      if (FREE_TRIAL_PLANS.includes(globalStateAtom?.selected_plan)) {
        // Add free trial plan to checkout link
        checkoutLink += `&plan=${globalStateAtom.selected_plan}`;
      } else if (globalStateAtom?.selected_coupon)
        // Add coupon ID to checkout link
        checkoutLink += `&coupon_id=${globalStateAtom.selected_coupon}`;
      setCheckoutLink(checkoutLink);

      const data = fetchedDataResponse?.data?.message;

      if (
        data?.prophone_contact_id &&
        data?.paid_url &&
        data?.salesloft_id &&
        data?.selected_plan &&
        data?.selected_plan !== NO_PLAN_SELECTED
      ) {
        setShowCheckoutLink(true);
      } else {
        setShowCheckoutLink(false);
      }
    }

    getLandingPage();
  }, [
    saveClickedCount,
    selectedProphoneContact,
    globalStateAtom?.selected_plan,
    globalStateAtom?.prophone_contact_id,
    globalStateAtom?.salesloft_id,
    globalStateAtom?.selected_coupon,
  ]);

  async function getPlansFromStripe() {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/stripe-get-all-plans`
    );
    const starterPlans = response.data.message.starter.data;
    const corePlans = response.data.message.core.data;
    const proPlans = response.data.message.pro.data;

    const allPlans = {} as StripePlansResponse;
    allPlans["starter"] = starterPlans as IStripePlan[];
    allPlans["core"] = corePlans as IStripePlan[];
    allPlans["pro"] = proPlans as IStripePlan[];

    const transformedPlans = transformStripePlans(allPlans);

    // Add free trial plans to the first (current) tier
    transformedPlans[0].plans = [
      ...transformedPlans[0].plans,
      ...FREE_TRIAL_PLANS,
    ];

    setFormattedStripePlans(transformedPlans);
  }

  const getCouponsFromStripe = async () => {
    const coupons = await getAllStripeCoupons();
    setStripeCoupons(filterCoupons(coupons));
  };

  useEffect(() => {
    getProphoneContacts().then((contacts) => setProPhoneContacts(contacts));
  }, []);

  return (
    <>
      <div className="flex flex-col justify-start items-start bg-white p-6 rounded-lg">
        <div className="text-h1 font-bold">Checkout Link</div>

        <label className={`${label} pb-1 w-full`}>
          Plan
          <select
            onChange={(e) => {
              setGlobalState({
                ...globalStateAtom,
                selected_plan: e.target.value,
              });
            }}
            value={globalStateAtom?.selected_plan}
            className={`${borderedInputWhite} flex py-2 pb-3`}
          >
            <option value={NO_PLAN_SELECTED} selected>
              {NO_PLAN_SELECTED}
            </option>
            {formattedStripePlans?.map((plans) => {
              return (
                <optgroup key={plans.tier} label={"Tier - " + plans.tier}>
                  {plans.plans.map((plan, index) => {
                    return (
                      <option key={index} value={plan}>
                        {plan}
                      </option>
                    );
                  })}
                </optgroup>
              );
            })}
          </select>
        </label>

        <label className={`${label} pb-1 w-full`}>
          Contact
          <select
            onChange={(e) => {
              setSelectedProphoneContact(e.target.value);
              setGlobalState({
                ...globalStateAtom,
                prophone_contact_id: e.target.value,
              });
            }}
            value={selectedProphoneContact}
            className={`${borderedInputWhite} flex py-2 pb-3`}
          >
            <option className="hidden" selected>
              Please Select Contact
            </option>
            {proPhoneContacts &&
              proPhoneContacts.length > 0 &&
              proPhoneContacts.map((contact) => {
                return (
                  <option key={contact.display_id} value={contact.display_id}>
                    {contact.display_name}
                  </option>
                );
              })}
          </select>
        </label>

        <label className={`${label} pb-1 w-full`}>
          Salesloft ID
          <input
            className={`${borderedInputWhite} flex py-2 pb-3`}
            type="number"
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                salesloft_id: e.target.value,
              })
            }
            value={globalStateAtom?.salesloft_id}
          />
        </label>

        <label className={`${label} pb-1 w-full`}>
          Coupon
          <select
            onChange={(e) => {
              if (e.target.value === NO_COUPON_SELECTED) {
                setGlobalState({
                  ...globalStateAtom,
                  selected_coupon: "",
                });
              } else {
                const newSelectedCoupon = stripeCoupons.find(
                  (coupon) => coupon.id === e.target.value
                );
                newSelectedCoupon &&
                  setGlobalState({
                    ...globalStateAtom,
                    selected_coupon: newSelectedCoupon.id,
                  });
              }
            }}
            value={globalStateAtom?.selected_coupon || NO_COUPON_SELECTED}
            className={`${borderedInputWhite} flex py-2 pb-3`}
          >
            <option value={NO_COUPON_SELECTED} selected>
              {NO_COUPON_SELECTED} (optional)
            </option>
            {stripeCoupons?.map((coupon) => {
              let displayedText = "";

              if (coupon.name) {
                displayedText = coupon.name;
              } else if (coupon.percent_off) {
                displayedText = `${coupon.percent_off}%`;
              } else {
                displayedText = `$${coupon.amount_off}`;
              }
              return (
                <option key={coupon.id} value={coupon.id}>
                  {displayedText}
                </option>
              );
            })}
          </select>
        </label>

        <label className={`${label} pb-1 w-full flex flex-col`}>
          Checkout Link
          <div
            className={`${showCheckoutLink ? "text-gray-900" : "text-red-600"}`}
          >
            {showCheckoutLink
              ? checkoutLink
              : 'Add a Contact and Paid URL, select a plan, and enter Salesloft ID. Then click "Save"'}
          </div>
        </label>
        <div className="flex justify-start">
          {showCheckoutLink && (
            <button
              className={`${buttonWhiteSmall}`}
              onClick={() => {
                navigator.clipboard.writeText(checkoutLink);
                return;
              }}
            >
              copy
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckoutLink;
