/* eslint-disable */
import axios from "axios";

export const primaryCategories = [
  "commercial_cleaning_service",
  "painting",
  "lawn_care_service",
  "interior_construction_contractor",
  "pressure_washing_service",
  "garage_builder",
  "lawn_sprinkler_system_contractor",
  "debris_removal_service",
  "tree_service",
  "house_cleaning_service",
  "car_detailing_service",
  "auto_repair_shop",
  "home_help",
  "property_management_company",
];

function getAccountData(company_name) {
  return {
    name: "gmb_autocreate",
    accountName: company_name,
    primaryOwner: "accounts/106131752896257989837", // ProPhone organization account id
    type: "LOCATION_GROUP",
  };
}

function getHours(string) {
  return parseInt(string.split(":")[0]);
}

function getMinutes(string) {
  return parseInt(string.split(":")[1]);
}

async function getCustomerData(customerData) {
  try {
    const customer = { admin: customerData.email, role: "MANAGER" };
    return customer;
  } catch (e) {
    alert("Failed to make user admin" + e);
  }
}

function getAddressObject(address, city, state) {
  return {
    addressLines: [address.split(",")[0]],
    locality: address.split(",")[address.split(",").length - 2],
    postalCode:
      address.split(" ")[address.split(" ").length - 1].toString().trim() ||
      city,
    administrativeArea: address.split(" ")[address.split(" ").length - 2],
    regionCode: "US" || state,
  };
}

function getPostalAddress(address) {
  return {
    revision: 0,
    regionCode: "US",
    locality: address.split(",")[address.split(",").length - 2],
    postalCode: address
      .split(" ")
      [address.split(" ").length - 1].toString()
      .trim(),
    administrativeArea: address.split(" ")[address.split(" ").length - 2],
    addressLines: [address.split(",")[0]],
  };
}

async function getLocationData(
  sitePk: string,
  business_hours: any[],
  company_name: string,
  paid_url: string,
  about: string,
  primaryCategory: string,
  address: string,
  city: string,
  state: string,
  phoneNumber: string,
  service_areas: any
) {
  const placeName = service_areas[0]?.city + " " + service_areas[0]?.state;
  const place = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/api/get_place_id_by_text_query`,
    {
      textQuery: placeName,
    }
  );
  console.log(place, "place");
  const gmb = {
    storeCode: sitePk.toString() + "-1",
    languageCode: "en",
    title: company_name,
    phoneNumbers: {
      primaryPhone: phoneNumber,
    },
    // storefrontAddress: getAddressObject(address, city, state),
    serviceArea: {
      businessType: "CUSTOMER_LOCATION_ONLY",
      regionCode: "US",
      places: {
        placeInfos: [
          {
            name: place.data.message.data.name,
            placeId: place.data.message.data.place_id,
          },
        ],
        // TODO: enable multiple service_areas
      },
    },
    websiteUri: paid_url,
    regularHours: {
      periods: [
        business_hours[0].from &&
          business_hours[0].to && {
            openDay: "MONDAY",
            openTime: {
              hours: getHours(business_hours[0].from),
              minutes: getMinutes(business_hours[0].from),
              seconds: 0,
              nanos: 0,
            },
            closeDay: "MONDAY",
            closeTime: {
              hours: getHours(business_hours[0].to),
              minutes: getMinutes(business_hours[0].to),
              seconds: 0,
              nanos: 0,
            },
          },
        business_hours[1].from &&
          business_hours[1].to && {
            openDay: "TUESDAY",
            openTime: {
              hours: getHours(business_hours[1].from),
              minutes: getMinutes(business_hours[1].from),
              seconds: 0,
              nanos: 0,
            },
            closeDay: "TUESDAY",
            closeTime: {
              hours: getHours(business_hours[1].to),
              minutes: getMinutes(business_hours[1].to),
              seconds: 0,
              nanos: 0,
            },
          },
        business_hours[2].from &&
          business_hours[2].to && {
            openDay: "WEDNESDAY",
            openTime: {
              hours: getHours(business_hours[2].from),
              minutes: getMinutes(business_hours[2].from),
              seconds: 0,
              nanos: 0,
            },
            closeDay: "WEDNESDAY",
            closeTime: {
              hours: getHours(business_hours[2].to),
              minutes: getMinutes(business_hours[2].to),
              seconds: 0,
              nanos: 0,
            },
          },
        business_hours[3].from &&
          business_hours[3].to && {
            openDay: "THURSDAY",
            openTime: {
              hours: getHours(business_hours[3].from),
              minutes: getMinutes(business_hours[3].from),
              seconds: 0,
              nanos: 0,
            },
            closeDay: "THURSDAY",
            closeTime: {
              hours: getHours(business_hours[3].to),
              minutes: getMinutes(business_hours[3].to),
              seconds: 0,
              nanos: 0,
            },
          },
        business_hours[4].from &&
          business_hours[4].to && {
            openDay: "FRIDAY",
            openTime: {
              hours: getHours(business_hours[4].from),
              minutes: getMinutes(business_hours[4].from),
              seconds: 0,
              nanos: 0,
            },
            closeDay: "FRIDAY",
            closeTime: {
              hours: getHours(business_hours[4].to),
              minutes: getMinutes(business_hours[4].to),
              seconds: 0,
              nanos: 0,
            },
          },
        business_hours[5].from &&
          business_hours[5].to && {
            openDay: "SATURDAY",
            openTime: {
              hours: getHours(business_hours[5].from),
              minutes: getMinutes(business_hours[5].from),
              seconds: 0,
              nanos: 0,
            },
            closeDay: "SATURDAY",
            closeTime: {
              hours: getHours(business_hours[5].to),
              minutes: getMinutes(business_hours[5].to),
              seconds: 0,
              nanos: 0,
            },
          },
        business_hours[6].from &&
          business_hours[6].to && {
            openDay: "SUNDAY",
            openTime: {
              hours: getHours(business_hours[6].from),
              minutes: getMinutes(business_hours[6].from),
              seconds: 0,
              nanos: 0,
            },
            closeDay: "SUNDAY",
            closeTime: {
              hours: getHours(business_hours[6].to),
              minutes: getMinutes(business_hours[6].to),
              seconds: 0,
              nanos: 0,
            },
          },
      ],
    },
    profile: {
      description: about,
    },
    categories: {
      primaryCategory: {
        name: `gcid:${primaryCategory}`,
      },
    },
  };
  return gmb;
}

// function getPhotoData(photo, category, company_name) {
//     const isGalleryPhoto =
//         category !== 'LOGO' && category !== 'COVER' && category !== 'PROFILE'
//     return {
//         name: isGalleryPhoto
//             ? photo.gallery + '-' + photo.order
//             : company_name + '-' + category,
//         mediaFormat: 'MEDIA_FORMAT_UNSPECIFIED', // enum: PHOTO, VIDEO, MEDIA_FORMAT_UNSPECIFIED
//         locationAssociation: {
//             category,
//         },
//         description:
//             photo.alt_tag || company_name + ' ' + category.toLowerCase(),
//         sourceUrl: isGalleryPhoto ? photo.photo : photo,
//     }
// }

export async function createGMBAccount(
  company_name: string,
  gmbAccessToken: string
) {
  // doc: https://developers.google.com/my-business/reference/accountmanagement/rest/v1/accounts#Account
  const createUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/accounts`;
  const value = await getAccountData(company_name);
  const createdAccount = await axios.post(
    createUrl,
    { ...value },
    {
      headers: {
        authorization: "Bearer " + gmbAccessToken,
        "Content-Type": "application/json",
      },
    }
  );
  return createdAccount.data;
}

export async function getAllGMBAccounts(gmbAccessToken: string) {
  // doc: https://developers.google.com/my-business/reference/accountmanagement/rest/v1/accounts#Account
  const createUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/accounts`;
  const createdAccount = await axios.get(createUrl, {
    headers: {
      authorization: "Bearer " + gmbAccessToken,
      "Content-Type": "application/json",
    },
  });
  return createdAccount.data;
}

export async function getLocationsByAccountId(
  account: string,
  gmbAccessToken: string
) {
  // doc: https://developers.google.com/my-business/reference/accountmanagement/rest/v1/accounts#Account
  const createUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/${account}/locations?read_mask=name,title`;
  // const createUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/accounts/107213582681738842803/locations?read_mask=name,title`
  const locationRes = await axios.get(createUrl, {
    headers: {
      authorization: "Bearer " + gmbAccessToken,
    },
  });
  return locationRes.data.locations;
}

export async function getMetadata(
  account: string,
  company_name: string,
  gmbAccessToken: string
) {
  // doc: https://developers.google.com/my-business/reference/accountmanagement/rest/v1/accounts#Account
  // const createUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/accounts/107213582681738842803/locations?read_mask=metadata,title`
  const createUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/${account}/locations?read_mask=metadata,title`;
  const locationRes = await axios.get(createUrl, {
    headers: {
      authorization: "Bearer " + gmbAccessToken,
    },
  });
  console.log(locationRes.data.locations, "locations");
  const location = locationRes.data.locations[0];
  // const location = locationRes.data.locations.find(
  //     // TODO: change this when multiple locations for each business
  //     (loc) => loc.title === company_name
  // )
  return location.metadata && location.metadata;
}

export async function getLocationVerificationStatus(
  locations: any,
  gmbAccessToken: string
) {
  const createUrl = `https://mybusinessverifications.googleapis.com/v1/${locations[0].name}/verifications?access_token=${gmbAccessToken}`;
  const verifications = await axios.get(createUrl, {
    headers: {
      authorization: "Bearer " + gmbAccessToken,
    },
  });
  let verifiedState = false;
  verifications.data.verifications?.forEach((verif) => {
    if (verif.state === "COMPLETED") verifiedState = true; // a verification was completed, but unless there's metadata attached to the location the full verification process has not been completed
  });
  return verifiedState;
}

export async function getGMBReviews(gmbAccessToken) {
  const createUrl = `https://mybusiness.googleapis.com/v4/accounts/107213582681738842803/locations/13327188690133619531/reviews`; // TODO: make this a dynamic route when we do reviews
  const reviews = await axios.get(createUrl, {
    headers: {
      authorization: "Bearer " + gmbAccessToken,
    },
  });
  console.log(reviews, "reviews");
  return reviews.data;
}

export async function getGMBPhotos(account, location, gmbAccessToken) {
  const createUrl = `https://mybusiness.googleapis.com/v4/${account}/${location}/media`;
  const reviews = await axios.get(createUrl, {
    headers: {
      authorization: "Bearer " + gmbAccessToken,
    },
  });
  return reviews.data;
}

export async function createGMBLocation(
  accountId: string,
  sitePk: string,
  business_hours: string,
  company_name: string,
  paid_url: string,
  about: string,
  primaryCategory: string,
  address: string,
  city: string,
  state: string,
  phoneNumber: string,
  service_areas: any,
  errorCallback: any,
  gmbAccessToken: string
): any | null {
  if (accountId) {
    const createUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/accounts/${accountId}/locations?validateOnly=False`;
    const value = await getLocationData(
      sitePk,
      business_hours,
      company_name,
      paid_url,
      about,
      primaryCategory,
      address,
      city,
      state,
      phoneNumber,
      service_areas
    );
    const createdLocation = await axios
      .post(
        createUrl,
        { ...value },
        {
          headers: {
            authorization: "Bearer " + gmbAccessToken,
            "Content-Type": "application/json",
          },
        }
      )
      .catch(function (error) {
        if (error.response) {
          const e = error.response.data.error;
          console.log(e.error, "eroiu");
          if (
            e.details[0].reason === "INVALID_ADDRESS" ||
            e.details[0].reason === "PIN_DROP_REQUIRED"
          ) {
            errorCallback(
              "Invalid address. Please use the exact address taken from google maps."
            );
          }
        }
      });
    if (createdLocation && createdLocation.data) return createdLocation.data;
  } else {
    console.log("bad accou tn id");
  }
}

export async function makeCustomerAdmin(
  accountId: string,
  customerData: string,
  gmbAccessToken: string
) {
  const createUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/accounts/${accountId}/admins`;
  const value = await getCustomerData(customerData);
  const invitation = await axios.post(
    createUrl,
    { ...value },
    {
      headers: {
        authorization: "Bearer " + gmbAccessToken,
        "Content-Type": "application/json",
      },
    }
  );
  console.log(invitation.data);
  return invitation.data;
}

export async function addPhotoToLocation(
  photo,
  category, // https://developers.google.com/my-business/reference/rest/v4/accounts.locations.media#MediaItem.Category
  account,
  location,
  gmbAccessToken: string
): any {
  console.log(category, "cat");

  // const createUrl = `https://mybusiness.googleapis.com/v4/accounts/107213582681738842803/locations/13327188690133619531/media`
  const createUrl = `https://mybusiness.googleapis.com/v4/${account}/${location}/media`;
  const value = {
    mediaFormat: "PHOTO",
    locationAssociation: {
      category,
    },
    sourceUrl: photo,
    description: photo.split(
      "https://landing-page-app-hero-images.s3.amazonaws.com/media/"
    )[1],
  };
  let uploadedMedia;
  try {
    uploadedMedia = await axios.post(
      createUrl,
      { ...value },
      {
        headers: {
          authorization: "Bearer " + gmbAccessToken,
        },
      }
    );
  } catch (e) {
    console.log(e, "err");
  }
  console.log(uploadedMedia?.data, "return from gmb");
  // Error handling
  let returned;
  if (uploadedMedia?.data) returned = uploadedMedia?.data;
  else if (category === "COVER")
    returned =
      "Cover photo must be less than 2120px wide and 1928px tall. Also, check that the aspect ratio is around 1.77 (w:h).";
  else if (category === "LOGO") returned = "Error uploading logo";
  else returned = `Error uploading photo`;
  return returned;
}

export async function verifyLocation(
  customerData: any,
  accountId: string,
  sitePk: string,
  business_hours: string,
  company_name: string,
  paid_url: string,
  about: string,
  primaryCategory: string,
  address: string,
  city: string,
  state: string,
  errorCallback: any,
  phone: string,
  gmbAccessToken: string
): any {
  // doc: https://developers.google.com/my-business/reference/verifications/rpc/google.mybusiness.verifications.v1#google.mybusiness.verifications.v1.Location
  const postalAddress = getPostalAddress(address);
  const value = {
    name: company_name,
    primaryPhone: phone,
    address: postalAddress,
    primaryCategoryId: `gcid:${primaryCategory}`,
  };
  const verificationToken = await axios.post(
    "https://mybusinessverifications.googleapis.com/v1/verificationTokens:generate",
    { location: { ...value } },
    {
      headers: {
        authorization: "Bearer " + gmbAccessToken,
        "Content-Type": "application/json",
      },
    }
  );
  if (accountId) {
    const createUrl = `https://mybusinessverifications.googleapis.com/v1/${location.id}:verify`;
    const value = {
      method: "EMAIL", // can be PHONE_CALL, SMS
      languageCode: "en",
      context: {
        address: getAddressObject(address, city, state),
      },
      emailAddress: customerData.email,
      mailerContact: address,
      phoneNumber: customerData.phone,
      token: {
        tokenString: verificationToken,
      },
    };
    const createdLocation = await axios.post(
      createUrl,
      { ...value },
      {
        headers: {
          authorization: "Bearer " + gmbAccessToken,
          "Content-Type": "application/json",
        },
      }
    );
    return createdLocation.data;
  } else {
    console.log("bad accou tn id");
  }
}

export const sections = [
  {
    label: "Launch Site Banner",
    section: "launch-site-banner",
    version: "v2",
    order: 0,
  },
  {
    label: "Navigation",
    section: "navigation",
    version: "v2",
    order: 1,
  },
  {
    label: "Header",
    section: "header",
    version: "v2",
    order: 2,
  },
  {
    label: "Hero",
    section: "hero",
    version: "v2",
    order: 3,
    v2ImageSource: "/hero-v2.png",
    v21ImageSource: "/hero-v2.1.png",
  },
  {
    label: "Logo w/ Text Grid",
    section: "logo-w-text-grid",
    version: "v2",
    order: 4,
    v2ImageSource: "/badges-v2.png",
    v21ImageSource: "/badges-v2.1.png",
  },
  {
    label: "About Us",
    section: "about-us",
    version: "v2",
    order: 5,
    v2ImageSource: "/about-us-v2.png",
    v21ImageSource: "/about-us-v2.1.png",
  },
  {
    label: "Logo Grid",
    section: "logo-grid",
    version: "v2",
    order: 6,
  },
  {
    label: "Our Services",
    section: "our-services",
    version: "v2",
    order: 7,
    v2ImageSource: "/our-services-v2.mov",
    v21ImageSource: "/our-services-v2.1.mov",
  },
  {
    label: "CTA",
    section: "cta",
    version: "v2",
    order: 8,
    v2ImageSource: "/CTA-v2.png",
    v21ImageSource: "/CTA-v2.1.png",
  },
  {
    label: "Get a Quote",
    section: "get_a_quote",
    version: "v2",
    order: 9,
    v2ImageSource: "/get-a-quote-v2.png",
    v21ImageSource: "/get-a-quote-v2.1.png",
  },
  {
    label: "Our Best Work",
    section: "our-best-work",
    version: "v2",
    order: 10,
    // v2ImageSource: "/our-best-work-v2.mov",
    // v21ImageSource: "/our-best-work-v2.1.mov",
    v22ImageSource: "/our-best-work-v2.2.mov",
  },
  {
    label: "Our Partners",
    section: "our-partners",
    version: "none",
    order: 11,
  },
  {
    label: "Reviews",
    section: "reviews",
    version: "v2",
    order: 12,
    v2ImageSource: "/review-v2.png",
    v21ImageSource: "/review-v2.1.png",
  },
  {
    label: "Pay Online",
    section: "pay-online",
    version: "none",
    order: 13,
  },
  {
    label: "FAQ",
    section: "faq",
    version: "v2",
    order: 14,
  },
  {
    label: "Service Areas",
    section: "service_areas",
    version: "v2",
    order: 15,
    v2ImageSource: "/service-areas-v2.png",
    v21ImageSource: "/service-areas-v2.1.png",
  },
  {
    label: "Final CTA",
    section: "final-cta",
    version: "v2",
    order: 16,
    v2ImageSource: "/final-cta-v2.png",
    v21ImageSource: "/final-cta-v2.1.png",
  },
  {
    label: "Footer",
    section: "footer",
    version: "v2",
    order: 17,
  },
  {
    label: "Text Us Button",
    section: "text-us-button",
    version: "v2",
    order: 18,
    // v2ImageSource: "/text-us-button-v2.mov",
    // v21ImageSource: "/text-us-button-v2.1.mov",
  },
  {
    label: "Services Page",
    section: "services-page",
    version: "v2",
    order: 19,
    v2ImageSource: "/services-v2.png",
    v21ImageSource: "/services-v2.1.png",
  },
  {
    label: "Gallery Page",
    section: "gallery-page",
    version: "v2",
    order: 20,
    v2ImageSource: "/galleries-v2.png",
    v21ImageSource: "/galleries-v2.1.png",
  },
  {
    label: "Reviews Page",
    section: "reviews-page",
    version: "v2",
    order: 21,
    v2ImageSource: "/reviews-page-v2.png",
    v21ImageSource: "/reviews-page-v2.1.png",
  },
  {
    label: "Jobs Page",
    section: "jobs-page",
    version: "v2",
    order: 22,
    v2ImageSource: "/jobs-v2.png",
    v21ImageSource: "/jobs-v2.1.png",
  },
  {
    label: "Team Page",
    section: "team-page",
    version: "v2",
    order: 23,
  },
  {
    label: "Custom Page",
    section: "custom-page",
    version: "none",
    order: 24,
  },
  {
    label: "Blog Page",
    section: "blog-page",
    version: "v2",
    order: 25,
  },
  {
    label: "Text Us Modal",
    section: "modals/text_us",
    version: "v2",
    order: 26,
    v2ImageSource: "/text-us-modal-v2.png",
    v21ImageSource: "/text-us-modal-v2.1.png",
  },
  {
    label: "Text Thank You Modal",
    section: "modals/text_thank_you",
    version: "v2",
    order: 27,
  },
  {
    label: "Leave a Review Modal",
    section: "modals/leave_a_review",
    version: "v2",
    order: 28,
  },
];

export const pages = [
  {
    label: "Services Page",
    section: "services-page",
    version: "v2",
    order: 0,
    v2ImageSource: "/services-v2.png",
    v21ImageSource: "/services-v2.1.png",
  },
  {
    label: "Gallery Page",
    section: "gallery-page",
    version: "v2",
    order: 1,
    v2ImageSource: "/galleries-v2.png",
    v21ImageSource: "/galleries-v2.1.png",
  },
  {
    label: "Reviews Page",
    section: "reviews-page",
    version: "v2",
    order: 2,
    v2ImageSource: "/reviews-page-v2.png",
    v21ImageSource: "/reviews-page-v2.1.png",
  },
  {
    label: "Jobs Page",
    section: "jobs-page",
    version: "v2",
    order: 3,
    v2ImageSource: "/jobs-v2.png",
    v21ImageSource: "/jobs-v2.1.png",
  },
  {
    label: "Team Page",
    section: "team-page",
    version: "v2",
    order: 4,
  },
  {
    label: "Custom Page",
    section: "custom-page",
    version: "none",
    order: 5,
  },
  {
    label: "Blog Page",
    section: "blog-page",
    version: "v2",
    order: 6,
  },
];

export const parkUserWebsiteProsite = async (
  domain: string,
  park_type: "Involuntary Churn" | "Voluntary Churn" | "No Message"
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/api/park_user_website`,
    {
      domain,
      park_type,
    }
  );
  return response.data;
};

export const getDefaultCustomLayout = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/get_custom_layout_presets/default`
  );

  return response.data.message;
}

export const getDefaultCustomCSS = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/get_custom_css_presets/default`
  );
  
  return response.data.message;
}


export const purchaseDomainHelper = async (domain: string, slug: string) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/purchase-domain`, {domain, slug})
    return response.data.message
  } catch(error){
    return 'error'
  }
}

export const checkDomainAvailability = async (domain: string, slug: string) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/check-domain-availability`, {domain, slug})
    return response.data.message
  } catch (error){
    return 'error'
  }
}

export const configureDNSHelper = async (domain: string, slug: string) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/configure-dns`, {domain, slug})
    return response.data.status
  } catch(error){
    return 'error'
  }
}
export const configureForExtDns = async (domain: string, slug: string) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/configure-for-ext-domain`, { slug})
    return response?.data?.message
  } catch(error){
    return 'error'
  }
}